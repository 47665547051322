import { StyledTag } from "./styled";

export const StatusTag = ({ status }: { status: string }) => {
  switch (status) {
    case "ACTIVE":
      return (
        <StyledTag variant="success" disabled={true} hasLeftDotIcon={true}>
          Ativo
        </StyledTag>
      );

    case "INACTIVE":
      return (
        <StyledTag variant="error" disabled={true} hasLeftDotIcon={true}>
          Inativo
        </StyledTag>
      );

    case "INVITATION_SENT":
      return (
        <StyledTag variant="secondary" disabled={true} hasLeftDotIcon={true}>
          Convite enviado
        </StyledTag>
      );

    case "INVITATION_EXPIRED":
      return (
        <StyledTag variant="secondary" disabled={true} hasLeftDotIcon={true}>
          Convite expirado
        </StyledTag>
      );

    case "INVITATION_PENDING":
      return (
        <StyledTag variant="info" disabled={true} hasLeftDotIcon={true}>
          Aguardando convite
        </StyledTag>
      );

    default:
      return <></>;
  }
};
