import styled from "styled-components";
import { ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledShapeIcon = styled(ShapeIcon)`
  stroke-width: 1.6;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  align-items: center;
`;

export const FallbackButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ConfirmWordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
