import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Table,
  tableControllers,
  Typography,
  EmptyState,
  Skeleton,
  GenericProfilePicture,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styled";

import { ITableGrid } from "./types";

import { routes } from "src/routes/index";
import { StatusTag } from "@components/StatusTag";
import { EmployeeGroups } from "./components/EmployeeGroups";

export const TableGrid = ({
  employees,
  loading,
  hasRoles,
  selectedRole,
  paginationState,
  setPagination,
  onRemoveEmployeeFromRole,
}: ITableGrid) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        header: "Nome",
        accessorKey: "name",
        cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={data?.cell?.row?.original?.name}
              />
              <FirstColumnCellData>
                <Typography variant="body4">
                  {data?.cell?.row?.original?.name}
                </Typography>
                {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (data: any) => (
          <StatusTag status={data?.cell?.row?.original?.status} />
        ),
      },
      {
        header: "Grupo",
        accessorKey: "groups",
        cell: (data: any) =>
          data?.cell?.row?.original?.groups && (
            <EmployeeGroups groups={data?.cell?.row?.original?.groups} />
          ),
      },
    ],
    []
  );

  const table = tableControllers.useTableColumns({
    columns,
    data: employees,
    pagination: paginationState,
    onPaginationChange: setPagination,
    options: {
      actions: () => [
        {
          label: "Desvincular do perfil de permissão",
          icon: "IconUserOff",
          key: "remove",
          onClick: (data) => onRemoveEmployeeFromRole(data),
        },
      ],
    },
  });

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!hasRoles && !selectedRole)
    return (
      <EmptyState
        buttonText={"Criar novo perfil de permissão"}
        description={"Você ainda não criou nenhum perfil de permissão."}
        buttonProps={{
          size: "medium",
          onClick: () => navigate(routes.pageRolesCreate),
        }}
      />
    );

  if (!selectedRole)
    return (
      <EmptyState
        buttonText={"Selecione um perfil"}
        description={"Você ainda não selecionou nenhum perfil de permissão."}
        buttonProps={{
          disabled: true,
          size: "medium",
        }}
      />
    );

  if (!employees.length)
    return (
      <EmptyState
        buttonText={"Adicionar Integrantes"}
        description={
          selectedRole.membersCount > 0
            ? "Não encontramos nenhum colaborador para esta busca."
            : "Você ainda não adicionou ninguém a esse perfil de permissão."
        }
        buttonProps={{
          size: "medium",
          onClick: () =>
            navigate(
              routes.pageRolesEmployees.replace(
                ":roleId",
                selectedRole?.id || ""
              )
            ),
        }}
      />
    );

  return (
    <Table.Grid.Root>
      <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
      {table.rows.map((row, index) => (
        <Table.Grid.Row key={index + row.id} row={row} />
      ))}
    </Table.Grid.Root>
  );
};
