import styled from "styled-components";
import { Accordion } from "@flash-tecnologia/hros-web-ui-v2";

const AccordionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    &::before {
      background-color: transparent;
    }
  }
`;

export { AccordionsWrapper, StyledAccordion };
