import { PermissionOptions } from "../PermissionOptions";

import { AccordionsWrapper, StyledAccordion } from "./styled";

import { Feature } from "../../types";

interface PermissionAccordionProps {
  groups: Feature[];
  selectedPermissions: string[];
  readOnly?: boolean;
  handleChange: ({ ids, checked }: { ids: string[]; checked: boolean }) => void;
}

export const PermissionAccordions = ({
  selectedPermissions,
  groups,
  readOnly,
  handleChange,
}: PermissionAccordionProps) => {
  return (
    <AccordionsWrapper>
      {groups.map((group) => (
        <StyledAccordion variant="default" title={group.name} key={group.name}>
          <PermissionOptions
            readOnly={readOnly}
            group={group}
            handleChange={handleChange}
            selectedPermissions={selectedPermissions}
          />
        </StyledAccordion>
      ))}
    </AccordionsWrapper>
  );
};
