import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { AccordionContainer } from "./styled";

export const ConfigureSkeleton = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
      <Skeleton variant="rectangular" height="40px" />
      <AccordionContainer>
        <Skeleton variant="rounded" height="104px" />
        <Skeleton variant="rounded" height="104px" />
      </AccordionContainer>
    </div>
  );
};
