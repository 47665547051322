import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;

  @media screen and (min-width: 900px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 782px;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Spacer = styled.div<{ width?: number; height?: number }>`
  width: ${(props) => props.width || 1}px;
  height: ${(props) => props.height || 1}px;
`;
