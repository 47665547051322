import { useTransferList } from "../../Root";
import {
  GenericProfilePicture,
  Typography,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  ListItemStyled,
  ListItemName,
  IconStyled,
  EmptyListContainer,
  BodyStyled,
  List,
  ListItemTrashStyled,
} from "./styled";

function ListItem({ data }: { data: { id: string; name: string } }) {
  const { removeSelected } = useTransferList();
  return (
    <>
      <ListItemStyled key={data.id}>
        <GenericProfilePicture size={40} name={data.name} />
        <ListItemName>
          <Typography variant="body4">{data.name}</Typography>
        </ListItemName>
        <ListItemTrashStyled>
          <Icons
            name="IconTrash"
            size={16}
            onClick={() => removeSelected([data])}
          />
        </ListItemTrashStyled>
      </ListItemStyled>
    </>
  );
}

function EmptyList() {
  return (
    <>
      <EmptyListContainer>
        <IconStyled>
          <Icons name="IconUsers" size={40} color="#6B5B66" />
        </IconStyled>
        <Typography variant="headline9">Nenhuma pessoa selecionada</Typography>
        <BodyStyled>
          <Typography variant="body4">
            Selecione as pessoas que deseja adicionar na lista ao lado
          </Typography>
        </BodyStyled>
      </EmptyListContainer>
    </>
  );
}

export function SelectedList() {
  const { selectedFiltered } = useTransferList();
  if (!selectedFiltered.length) {
    return <EmptyList />;
  }

  return (
    <>
      <List>{selectedFiltered.map((item) => ListItem({ data: item }))}</List>
    </>
  );
}
