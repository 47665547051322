import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 490px;
  overflow-y: auto;

  @media screen and (max-width: 900px) {
    max-height: 200px;
  }
`;

export { Container };
