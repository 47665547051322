import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Menu, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Card,
  Title,
  Counter,
  CounterText,
  StyledIcon,
  Option,
} from "./styled";

import { routes } from "src/routes";

export type BaseTabCardType = { key: string; title: string };

export type TabCardType = {
  id: string;
  isSelected: boolean;
  setSelectedRole: (key: string) => void;
  onDelete?: (roleId: string) => void;
  count?: number;
  locked: boolean;
  name: string;
};

export const TabCard = ({
  id,
  isSelected,
  name,
  count,
  locked,
  onDelete,
  setSelectedRole,
}: TabCardType) => {
  const navigate = useNavigate();

  const options = useMemo(() => {
    const option = [
      {
        key: "edit",
        children: (
          <Option
            onClick={() => {
              navigate(routes.pageRolesPermissions.replace(":roleId", id));
            }}
          >
            <Icons size={24} name="IconPencil" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              {locked ? "Visualizar permissões" : "Editar perfil de permissão"}
            </Typography>
          </Option>
        ),
      },
    ];

    if (!locked && onDelete)
      option.push({
        key: "delete",
        children: (
          <Option onClick={() => onDelete(id)}>
            <Icons
              name="IconTrash"
              size={24}
              fill="transparent"
              color="#C96C01"
            />
            <Typography
              variant="body3"
              style={{ color: "#C96C01", fontWeight: 600 }}
            >
              Excluir perfil de permissão
            </Typography>
          </Option>
        ),
      });

    return option;
  }, []);

  return (
    <>
      <Card key={id} selected={isSelected} onClick={() => setSelectedRole(id)}>
        <Title variant="body4" tag="span" selected={isSelected}>
          {name}
        </Title>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Counter selected={isSelected}>
            <CounterText variant="caption" selected={isSelected}>
              {count}
            </CounterText>
          </Counter>
          {locked && (
            <StyledIcon
              name="IconLock"
              size={24}
              fill="transparent"
              color="#D1C7CE"
            />
          )}

          <Menu type={"select"} options={options} disableAutoFocusItem={true}>
            <StyledIcon
              name="IconDotsVertical"
              size={17}
              fill="#6B5B66"
              color="#6B5B66"
            />
          </Menu>
        </div>
      </Card>
    </>
  );
};
