import styled from "styled-components";

import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid #ebe6e9;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-left: 15px;
`;
