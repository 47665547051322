import { forwardRef, useImperativeHandle } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  TextField,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledFormTitle,
  StyledFormDescription,
  FormFieldsContainer,
  StyledSubtitleContainer,
} from "./styled";

const validationSchema = yup.object({
  name: yup.string().required("Esse campo deve ser preenchido"),
  description: yup.string().notRequired(),
});

export type FormDataHandle = {
  handleSubmit: () => void;
  resetForm: () => void;
};

interface FormDataProps {
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (input: { name: string; description: string }) => void;
}

export const FormData = forwardRef<FormDataHandle, FormDataProps>(
  ({ onSubmit, loading, disabled }, ref) => {
    const formik = useFormik({
      initialValues: {
        name: "",
        description: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => onSubmit(values),
    });

    useImperativeHandle(ref, () => ({
      handleSubmit: () => formik.handleSubmit(),
      resetForm: () => formik.resetForm(),
    }));

    return (
      <form onSubmit={formik.handleSubmit}>
        <StyledFormTitle variant="headline8">Nome</StyledFormTitle>
        <StyledFormDescription variant="body4">
          Dê um nome ao perfil de permissão, este será o principal texto exibido
          nas listas e nos destaques.
        </StyledFormDescription>
        {loading ? (
          <FormFieldsContainer>
            <Skeleton variant="rounded" height={"56px"} />
          </FormFieldsContainer>
        ) : (
          <FormFieldsContainer>
            <div>
              <TextField
                id={"name"}
                name={"name"}
                label="Nome do perfil de permissão"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                disabled={disabled}
              />
            </div>
            <div>
              <TextField
                id={"description"}
                name={"description"}
                label="Descrição do perfil de permissão"
                multiline={true}
                rows={4}
                fullWidth
                inputProps={{
                  maxlength: 600,
                }}
                value={formik.values.description}
                onChange={formik.handleChange}
                disabled={disabled}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
              <StyledSubtitleContainer>
                <Typography variant="caption">Máximo 600 caracteres</Typography>
                <Typography variant="caption">
                  {formik.values.description.length}/600
                </Typography>
              </StyledSubtitleContainer>
            </div>
          </FormFieldsContainer>
        )}
      </form>
    );
  }
);
