import { GroupListContainer, StyledTag } from "./styled";

export interface IEmployeeGroupsProps {
  groups: { name: string }[];
}

export const EmployeeGroups = ({ groups }: IEmployeeGroupsProps) => {
  const renderedGroups =
    groups.length > 3
      ? [
          <StyledTag variant="gray" disabled={true} key={1}>
            {groups[0].name}
          </StyledTag>,
          <StyledTag variant="gray" disabled={true} key={2}>
            {groups[1].name}
          </StyledTag>,
          <StyledTag variant="gray" disabled={true} key={3}>
            {`+${groups.length - 2}`}
          </StyledTag>,
        ]
      : groups?.map(({ name }, index) => (
          <StyledTag variant="gray" disabled={true} key={index}>
            {name}
          </StyledTag>
        ));

  return <GroupListContainer>{renderedGroups}</GroupListContainer>;
};
