import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import {
  Container,
  TextWrapper,
  Subtitle,
  Title,
  StyledModal,
  Warning,
  AlertWrapper,
  AlterIcon,
  ConfirmWordWrapper,
  ConfirmDescription,
  ConfirmInput,
} from "./styled";
import { ModalConfirmActionInterface } from "./types";
import { useState } from "react";

export const ModalConfirmAction = ({
  isOpen,
  loading,
  title,
  description,
  submitText,
  confirmWord,
  onClose,
  onSubmit,
  submitButtonStyle,
}: ModalConfirmActionInterface) => {
  const word = confirmWord?.toLocaleUpperCase();
  const [inputWord, setInputWord] = useState("");

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          submitText={submitText}
          loading={loading}
          disabled={confirmWord ? inputWord !== word : false}
          submitButtonStyle={submitButtonStyle}
        />
      }
    >
      <Container>
        <TextWrapper>
          <AlertWrapper>
            <AlterIcon name="IconAlertCircle" size={64} fill="transparent" />
          </AlertWrapper>
          <Warning variant="body3">Atenção!</Warning>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
          {confirmWord && (
            <ConfirmWordWrapper>
              <ConfirmDescription variant="body3">
                Digite {word} e confirme
              </ConfirmDescription>
              <ConfirmInput
                label={`Digite ${word}`}
                onChange={(e) => setInputWord(e.target.value)}
              />
            </ConfirmWordWrapper>
          )}
        </TextWrapper>
        <IconButton
          icon="IconX"
          onClick={onClose}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
