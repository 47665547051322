import { useNavigate } from "react-router-dom";
import {
  Button,
  Icons,
  Typography,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import * as Styled from "./styled";

import { routes } from "src/routes/index";

type HeaderProps = {
  administratorsCount: number;
};

const getAdministratorsString = (count: number) => {
  if (count === 0) {
    return "Nenhuma pessoa";
  }

  if (count === 1) {
    return "1 pessoa";
  }

  return `${count} pessoas`;
};

export const Header = ({ administratorsCount }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Typography variant="headline6">Super administradores</Typography>
        <Typography variant="body4">
          Os super administradores possuem nível máximo de permissão na
          plataforma, tendo acesso a informações e configurações de{" "}
          <strong>todas as empresas do grupo.</strong>
        </Typography>
        <Styled.Spacer height={32} />
        <Styled.Row>
          <Typography variant="headline8">
            Pessoas super administradoras
          </Typography>
          <Styled.Spacer width={12} />
          <Tag variant="gray">
            {getAdministratorsString(administratorsCount)}
          </Tag>
        </Styled.Row>
      </Styled.Wrapper>
      <span>
        <Button
          size="large"
          variant="primary"
          onClick={() => navigate(routes.pageSetGlobalAdmins)}
        >
          Atribuir novo super administrador <Icons name="IconPlus" size={24} />
        </Button>
      </span>
    </Styled.Container>
  );
};
