import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "src/routes";

export const useEmployeeIdsByRoleQuery = () => {
  const { data, mutate, isLoading } = trpc.getEmployeesIdsByRole.useMutation();

  const fetch = useCallback(
    (input: { roleId: string }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os colaboradores",
            type: "error",
          });
        },
      });
    },
    [mutate]
  );

  return { data, fetch, isLoading };
};

export const useEmployeeQuery = () => {
  const { data, mutate, isLoading } = trpc.getEmployeesPaginated.useMutation();

  const fetch = useCallback(
    (input: {
      searchQuery?: string;
      page: number;
      pageSize: number;
      companyId?: string;
      includeProfilePicture?: boolean;
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os colaboradores",
            type: "error",
          });
        },
      });
    },
    [mutate]
  );

  return { data, fetch, isLoading };
};

export const useUpdateRoleMutation = () => {
  const { mutate: _mutate, isLoading } = trpc.editRoleEmployees.useMutation();
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const mutate = useCallback(
    (input: { roleId: string; employeeIds: string[] }) => {
      _mutate(input, {
        onSuccess: async () => {
          await utils.getRoleEmployeesPaginated.invalidate();

          dispatchToast({
            type: "success",
            content: "Perfil atualizado com sucesso!",
          });

          navigate(routes.pageRoles + `/${input.roleId}`);
        },
        onError: (error) => {
          dispatchToast({
            type: "error",
            content: "Erro ao Adicionar pessoas no perfil",
            description: error.message,
          });
        },
      });
    },
    [navigate, _mutate]
  );

  return { mutate, isLoading };
};
