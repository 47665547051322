import { CSSProperties, ReactNode } from "react";
import {
  FooterCancelButton,
  FooterContainer,
  SubmitButton,
  SubmitButtonText,
} from "./styled";

export const Footer = ({
  onClose,
  onSubmit,
  submitText,
  loading,
  disabled,
  submitButtonStyle,
}: FooterProps) => {
  return (
    <FooterContainer>
      <FooterCancelButton onClick={onClose}>Cancelar</FooterCancelButton>
      <SubmitButton
        variant={"primary"}
        variantType={"error"}
        buttonType={"primary"}
        size={"medium"}
        style={
          submitButtonStyle || {
            margin: "auto 0",
            padding: "19px 52px",
          }
        }
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
        disableRipple
        disableTouchRipple
      >
        <SubmitButtonText disabled={disabled}>{submitText}</SubmitButtonText>
      </SubmitButton>
    </FooterContainer>
  );
};

type FooterProps = {
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  disabled: boolean;
  submitText: string | ReactNode;
  submitButtonStyle?: CSSProperties;
};
