import { TabCard } from "../TabCard";
import { Role } from "src/types";

import { Container } from "./styled";

export interface TabContentInterface {
  selectedRole: Role | null;
  setSelectedRole: (key: string) => void;
  onDeleteRole?: (roleId: string) => void;
  roles: Role[];
}

export const TabContent = ({
  selectedRole,
  setSelectedRole,
  roles,
  onDeleteRole,
}: TabContentInterface) => {
  return (
    <Container>
      {roles?.map(({ name, id, membersCount }) => {
        const isSelected = id === selectedRole?.id;

        return (
          <TabCard
            key={id}
            id={id}
            count={membersCount}
            name={name}
            locked={selectedRole?.builtIn || false}
            isSelected={isSelected}
            setSelectedRole={setSelectedRole}
            onDelete={onDeleteRole}
          />
        );
      })}
    </Container>
  );
};
