import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const GroupListContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledTag = styled(Tag)`
  white-space: nowrap;
`;
