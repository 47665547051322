import { useMemo, Dispatch, useState, useEffect } from "react";
import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { TabContent } from "./TabContent";
import { Container } from "./styled";
import { Role } from "src/types";

export interface TabsInterface {
  selectedRole: Role | null;
  setSelectedRole: Dispatch<Role>;
  roles: Role[];
  onDeleteRole: (roleId: string) => void;
}

export const Tabs = ({
  selectedRole,
  setSelectedRole,
  roles,
  onDeleteRole,
}: TabsInterface) => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (selectedRole) setSelectedTab(selectedRole.builtIn ? 0 : 1);
  }, [selectedRole]);
  const tabItems = useMemo(() => {
    const items = [];
    const builtInRoles = roles.filter((p) => p.builtIn);
    const customRoles = roles.filter((p) => !p.builtIn);

    items.push({
      label: "Padrão",
      component: (
        <TabContent
          roles={builtInRoles}
          selectedRole={selectedRole}
          setSelectedRole={(id) => {
            const role = roles.find((role) => role?.id === id);
            if (role) setSelectedRole(role);
          }}
        />
      ),
    });

    items.push({
      label: "Personalizada",
      component: (
        <TabContent
          roles={customRoles}
          selectedRole={selectedRole}
          onDeleteRole={onDeleteRole}
          setSelectedRole={(id) => {
            const role = roles.find((role) => role?.id === id);
            if (role) setSelectedRole(role);
          }}
        />
      ),
    });
    return items;
  }, [roles, selectedRole, setSelectedRole, onDeleteRole]);

  return (
    <Container>
      <Tab
        selected={selectedTab}
        onTabChanged={setSelectedTab}
        tabItens={tabItems}
      />
    </Container>
  );
};
