import { useCallback } from "react";
import { trpc } from "@api/client";

export const useSetAdministrator = () => {
  const { data, mutateAsync, isLoading } = trpc.setAdmin.useMutation();

  const fetch = useCallback(
    (input: { employeeId: string; confirmationCode: string }) =>
      mutateAsync(input),
    [mutateAsync]
  );

  return { data, fetch, isLoading };
};

export const useGetAdminsIds = () => {
  const { data, isLoading } = trpc.getAdministratorsIds.useQuery();

  return { data, isLoading };
};
