import { Container } from "./styled";
import { SelectedListHeader, SelectedList } from "./index";

export function SelectedListArea() {
  return (
    <Container>
      <SelectedListHeader />
      <SelectedList />
    </Container>
  );
}
