import styled from "styled-components";
import { Icons, Modal, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { TextField } from "@mui/material";

export const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

export const AlertWrapper = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-feedback-error-90);
  border-radius: 50%;
`;

export const AlterIcon = styled(Icons)`
  && {
    color: var(--color-feedback-error-40);
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 420px;
  text-align: center;
  margin: 20px 48px 20px 88px;
`;

export const Warning = styled(Typography)`
  && {
    font-weight: 700;
    color: var(--color-feedback-error-pure);
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: var(--color-neutral-dark1);

  margin-bottom: 16px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: var(--color-neutral-50);
`;

export const ConfirmWordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ConfirmDescription = styled(Typography)`
  color: var(--color-neutral-30) !important;
  font-weight: 700 !important;
  margin: 44px 0 16px !important;
`;

export const ConfirmInput = styled(TextField)`
  width: 100%;
`;
