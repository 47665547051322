import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Card = styled.button<{ selected: boolean }>`
  display: flex;
  padding: 13px 11px;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  gap: 6px;

  border: 1px solid
    ${({ selected, theme }) => (selected ? theme.colors.primary : "#4A4E571A")};

  background: ${({ selected, theme }) =>
    selected ? theme.colors.secondary[95] : "transparent"};

  &:hover {
    background-color: #fff5fa;
  }
`;

const Title = styled(Typography)<{ selected: boolean }>`
  && {
    color: ${({ selected, theme }) =>
      selected ? theme.colors.primary : theme.colors.neutral[30]};
    font-weight: 600;
    user-select: none;
    word-break: break-word;
  }
`;

const Counter = styled.div<{ selected: boolean }>`
  padding: 4px 12px;
  background: ${({ theme, selected }) =>
    selected ? "transparent" : theme.colors.neutral[90]};
  border-radius: 24px;
  display: flex;
  justify-content: center;
`;

const CounterText = styled(Typography)<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary[50] : theme.colors.neutral[10]};
  font-weight: 600 !important;
`;

const StyledIcon = styled(Icons)``;

const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export { Card, Title, Counter, CounterText, StyledIcon, Option };
