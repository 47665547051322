import { Container } from "./styled";
import { TableGrid } from "./TableGrid";
import { TableSearch } from "./TableSearch";

import { Employee } from "src/types";
import { FetchData, PaginationInfo } from "../../types";

type TableProps = {
  loading: boolean;
  hasRoles: boolean;
  paginationInfo: PaginationInfo;
  employees: Employee[];
  searchValue: string;
  onSearch: (value: string) => void;
  fetchData: FetchData;
  onRemoveAdmin: (data: Employee) => void;
};

export const Table = ({
  employees,
  loading,
  hasRoles,
  paginationInfo,
  onSearch,
  searchValue,
  fetchData,
  onRemoveAdmin,
}: TableProps) => {
  return (
    <Container>
      <TableSearch value={searchValue} onChange={onSearch} />
      <TableGrid
        fetchData={fetchData}
        employees={employees}
        paginationInfo={{ ...paginationInfo, pageSize: 10 }}
        loading={loading}
        hasRoles={hasRoles}
        onRemoveAdmin={onRemoveAdmin}
      />
    </Container>
  );
};
