import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Search = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }
  margin-bottom: 32px;
  width: 100%;
  height: 60px;
`;

type TableSearchProps = {
  onChange: (value: string) => void;
  value: string;
};

export const TableSearch = ({ onChange, value }: TableSearchProps) => {
  const handleOnInput = (value: any) => {
    const target = value?.target?.value;
    onChange(target || "");
  };

  return (
    <Search
      onInputChange={handleOnInput}
      label="Buscar por pessoas"
      inputValue={value}
    />
  );
};
