import CompanyEmployeesTransferList, {
  transferListProps,
} from "@components/TransferList/transferList";
import {
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styled";
import { Employee } from "src/types";

export default function PageRoleEmployeeTransferList({
  data: employees,
  loading,
  tableTitle,
  tableTagLabel,
  onSearchChange,
  pagination,
  onPaginationChange,
  selected,
  setSelected,
  dataSize,
}: Omit<transferListProps<Employee>, "columns">) {
  const columns = [
    {
      header: "Nome",
      accessorKey: "name",
      cell: (context: any) => {
        const name = context?.cell?.row?.original?.name;
        const email = context?.cell?.row?.original?.email;
        return (
          <FirstColumnCell>
            <GenericProfilePicture size={40} name={name} />
            <FirstColumnCellData>
              <Typography variant="body4">{name}</Typography>
              {!!email && (
                <EmailWrapper>
                  <EmailIcon name="IconMail" size={16} fill="transparent" />
                  <EmailText variant="caption">{email}</EmailText>
                </EmailWrapper>
              )}
            </FirstColumnCellData>
          </FirstColumnCell>
        );
      },
    },
    {
      header: "Cargo",
      accessorKey: "role",
      cell: (context: any) => (
        <div style={{ width: "200px" }}>
          {context.cell.row.original.role.name}
        </div>
      ),
    },
  ];
  return (
    <CompanyEmployeesTransferList
      data={employees}
      columns={columns}
      loading={loading}
      tableTitle={tableTitle}
      tableTagLabel={tableTagLabel}
      onSearchChange={onSearchChange}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      selected={selected}
      setSelected={setSelected}
      dataSize={dataSize}
    />
  );
}
