import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "src/routes";

export const useCreateRoleMutation = () => {
  const { mutate: _mutate, isLoading } = trpc.createRole.useMutation();
  const navigate = useNavigate();

  const mutate = useCallback(
    (input: { companyId: string; name: string; description: string }) => {
      _mutate(input, {
        onSuccess: (data) => {
          navigate(routes.pageRolesPermissions.replace(":roleId", data.id));
        },
        onError: (error) => {
          dispatchToast({
            type: "error",
            content: "Erro ao criar perfil de permissão",
            description: error.message,
          });
        },
      });
    },
    [navigate, _mutate]
  );

  return { mutate, isLoading };
};
