import styled from "styled-components";

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  border-bottom: 1px solid var(--color-neutral-90);
  padding: ${({ theme }) =>
    `${theme.spacings.s} ${theme.spacings.xs} ${theme.spacings.xs}`};
`;

export const TitleStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const SearchStyled = styled.div`
  padding: 0px;
`;
