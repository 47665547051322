import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "src/routes";

export const useRolesAndPermissionsQuery = (roleId: string) => {
  const { data, isLoading } = trpc.getRole.useQuery(
    { roleId: roleId },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar permissões do perfil",
          description: "Tente novamente mais tarde",
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  return { data, isLoading };
};

export const useEditRolePermissionsMutation = () => {
  const navigate = useNavigate();
  const {
    mutate: _mutate,
    isLoading,
    error,
  } = trpc.editRolePermissions.useMutation();

  const mutate = useCallback(
    (input: { roleId: string; permissions: string[] }) => {
      _mutate(input, {
        onSuccess: () => {
          navigate(routes.pageRolesEmployees.replace(":roleId", input.roleId));
        },
        onError: (error) => {
          dispatchToast({
            type: "error",
            content: "Erro ao editar perfil de permissão",
            description: error.message,
          });
        },
      });
    },
    [navigate, _mutate]
  );

  return { mutate, isLoading, error };
};
