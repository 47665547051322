import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";
import { useTransferList } from "../../Root";
import { SearchStyled } from "./styled";
import { useCallback, useEffect, useState } from "react";

export default function Search() {
  const { setSelectedFiltered, selected } = useTransferList();
  const [lastSearch, setLastSearch] = useState<string>("");

  const onSearchChange = useCallback(
    (value: string) => {
      setLastSearch(value);
      const regexExp = new RegExp(`${value}`, "i");
      const updatedSelectedFiltered = selected.filter(
        ({ name }) => !!regexExp.test(name)
      );
      setSelectedFiltered(updatedSelectedFiltered);
    },
    [selected, setSelectedFiltered]
  );

  useEffect(() => {
    onSearchChange(lastSearch);
  }, [selected, lastSearch, onSearchChange]);

  return (
    <>
      <SearchStyled>
        <SearchField
          label="Pesquisar..."
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </SearchStyled>
    </>
  );
}
