import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { QueryClientProvider } from "@tanstack/react-query";
import "./i18n";
import { trpc, trpcClient, queryClient } from "./api/client";
import AppRouter from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "./utils";

function Root() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;
