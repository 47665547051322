import { IconButton, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import {
  Container,
  TextWrapper,
  Subtitle,
  Title,
  StyledModal,
  Warning,
  AlertWrapper,
  AlterIcon,
} from "./styled";

interface ModalConfirmActionInterface {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ModalWarn = ({
  isOpen,
  loading,
  onClose,
  onSubmit,
}: ModalConfirmActionInterface) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          loading={loading}
          disabled={false}
          isMobile={isMobile}
        />
      }
    >
      <Container>
        <TextWrapper>
          <AlertWrapper>
            <AlterIcon name="IconInfoCircle" size={30} fill="transparent" />
          </AlertWrapper>
          <Warning variant={isMobile ? "body4" : "body3"}>Atenção!</Warning>
          <Title variant={isMobile ? "headline8" : "headline6"}>
            Você não adicionou ninguém ao perfil de permissão. Deseja sair mesmo
            assim?
          </Title>
          <Subtitle variant={isMobile ? "body4" : "body3"}>
            Você poderá adicionar pessoas em outro momento.
          </Subtitle>
        </TextWrapper>
        <IconButton
          icon="IconX"
          onClick={onClose}
          variant="line"
          size="small"
          style={{ position: "absolute" }}
        />
      </Container>
    </StyledModal>
  );
};
