import { useCallback, useRef } from "react";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { PageTemplate } from "@components/index";
import { routes } from "../../routes/index";
import { FormData, FormDataHandle } from "./components/FormData";
import {
  Container,
  ContentContainer,
  TextWrapper,
  FormWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledDescription,
} from "./styled";
import { useCreateRoleMutation } from "./queries";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

export const PagePermissionsCreate = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormDataHandle>(null);
  const { selectedCompany } = useSelectedCompany();

  const routesBread = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
    },
    {
      label: "Acessos",
      route: routes.pageRoles,
    },
    { label: "Criar Perfil" },
  ];

  const { mutate: createRole, isLoading } = useCreateRoleMutation();

  const handleSubmit = useCallback(
    (props: { name: string; description: string }) => {
      createRole({
        name: props.name,
        companyId: selectedCompany.id,
        description: props.description,
      });
    },
    [createRole, selectedCompany]
  );

  return (
    <PageTemplate
      routes={routesBread}
      footer={{
        goBackProps: { disabled: true },
        cancelProps: {
          callback: () => navigate(routes.pageRoles),
        },
        confirmProps: {
          loading: isLoading,
          disabled: isLoading,
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" />
            </>
          ),
          callback: () => {
            formRef?.current?.handleSubmit();
          },
        },
      }}
    >
      <Container>
        <StyledTitle variant="headline6">Criar perfil de permissão</StyledTitle>
        <ContentContainer>
          <TextWrapper>
            <StyledSubtitle variant="headline8">
              Informações básicas
            </StyledSubtitle>
            <StyledDescription variant="body3">
              Adicione um nome ao perfil de permissão.
            </StyledDescription>
          </TextWrapper>
          <FormWrapper>
            <FormData
              ref={formRef}
              onSubmit={handleSubmit}
              disabled={isLoading}
            />
          </FormWrapper>
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};
