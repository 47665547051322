import { Root } from "./Root";
import { SelectedList, SelectedListArea } from "./selectedList";
import { Table } from "./table/table";

const TransferList = {
  Root,
  SelectedList,
  SelectedListArea,
  Table,
};

export default TransferList;
