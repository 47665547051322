import { useMemo } from "react";
import { Tab, EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { PermissionAccordions } from "../PermissionAccordions";
import { ConfigureSkeleton } from "../Skeleton";

import { Module } from "../../types";
import { Role } from "src/types";

interface PermissionTabsProps {
  modules: Module[];
  role: Role;
  loading?: boolean;
  error?: boolean;
  selectedPermissions: string[];
  handleChange: (props: { ids: string[]; checked: boolean }) => void;
}

export type PermissionsHandle = {
  getSelectedPermissions: () => string[];
};

export const PermissionTabs = ({
  modules,
  loading,
  error,
  role,
  selectedPermissions,
  handleChange,
}: PermissionTabsProps) => {
  const tabItens = useMemo(() => {
    return modules.map((module) => {
      return {
        label: module.name,
        component: (
          <PermissionAccordions
            groups={module.features || []}
            selectedPermissions={selectedPermissions}
            readOnly={role.builtIn}
            handleChange={handleChange}
          />
        ),
      };
    });
  }, [role.builtIn, modules, selectedPermissions, handleChange]);

  if (loading) return <ConfigureSkeleton />;

  if (error)
    return (
      <EmptyState
        buttonText={"Tentar novamente"}
        description={"Ops! Ocorreu um erro ao buscar as permissões"}
        buttonProps={{
          size: "medium",
          onClick: () => {
            window.location.reload();
          },
        }}
      />
    );

  return <Tab tabItens={tabItens} type="scrollable" />;
};
