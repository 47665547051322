import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const StyledFormTitle = styled(Typography)`
  margin-bottom: 4px;
`;

const StyledFormDescription = styled(Typography)`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export {
  StyledFormTitle,
  StyledFormDescription,
  FormFieldsContainer,
  StyledSubtitleContainer,
};
