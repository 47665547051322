// getAdministratorsRouter
import { useCallback } from "react";
import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";

export const useAdministratorsQuery = () => {
  const { data, mutate, isLoading } =
    trpc.getAdministratorsPaginated.useMutation();

  const fetch = useCallback(
    (input: {
      searchQuery?: string;
      page: number;
      pageSize: number;
      order?: "asc" | "desc";
      sortBy?: "name" | "status" | "documentNumber";
    }) => {
      mutate(input, {
        onError: () => {
          dispatchToast({
            content: "Não foi possível obter os administradores",
            type: "error",
          });
        },
      });
    },
    [mutate]
  );

  return { data, fetch, isLoading };
};

export const useUnsetAdministrator = (employee: { name: string }) => {
  const { mutate, isLoading } = trpc.unsetAdmin.useMutation();

  const fetch = useCallback(
    (input: { employeeId: string }) =>
      mutate(input, {
        onSuccess: () => {
          dispatchToast({
            content: `A pessoa ${employee.name} foi desvinculada do perfil de super administrador com sucesso!`,
            type: "success",
          });
        },
        onError: () => {
          dispatchToast({
            content: "Erro ao desvincular a pessoa",
            description: "Por favor, tente novamente.",
            type: "error",
          });
        },
      }),
    [mutate, employee]
  );

  return { fetch, isLoading };
};
