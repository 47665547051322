import styled from "styled-components";

export const Container = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacings.xs};
  border-left: 1px solid var(--color-neutral-90);
`;
