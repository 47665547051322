import {
  IFetchProps,
  Table as TableDS,
} from "@flash-tecnologia/hros-web-ui-v2";

import { TableActions } from "./TableActions";
import { TableGrid } from "./TableGrid";

import { Employee, Role } from "src/types";
import { useState } from "react";
import { PaginationState } from "../../types";

type TableProps = {
  selectedRole: Role | null;
  loading: boolean;
  hasRoles: boolean;
  employees: Employee[];
  searchValue: string;
  onSearch: (value: string) => void;
  onPaginationChanged: (input: IFetchProps) => void;
  onOrderChanged: (input: IFetchProps) => void;
  onRemoveEmployeeFromRole: (data: { id: string; name: string }) => void;
};

export const Table = ({
  selectedRole,
  employees,
  loading,
  hasRoles,
  onSearch,
  searchValue,
  onPaginationChanged,
  onOrderChanged,
  onRemoveEmployeeFromRole,
}: TableProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 10,
  });

  const membersCount = selectedRole?.membersCount || 0;

  return (
    <TableDS.Root>
      {hasRoles && (
        <TableDS.Header
          title="Pessoas neste perfil de permissão"
          tag="gray"
          tagLabel={
            membersCount == 1
              ? `${membersCount} pessoa`
              : `${membersCount} pessoas`
          }
        />
      )}
      <TableDS.Search
        onChange={(value) => onSearch(value?.target?.value || "")}
        label="Buscar por pessoas atribuídas"
        disabled={!selectedRole?.membersCount}
        inputValue={searchValue}
      />
      <TableActions
        roleId={selectedRole?.id || ""}
        disabled={!selectedRole?.id}
      />
      <TableGrid
        selectedRole={selectedRole}
        onOrderChanged={onOrderChanged}
        setPagination={setPagination}
        employees={employees}
        paginationState={pagination}
        loading={loading}
        hasRoles={hasRoles}
        onRemoveEmployeeFromRole={onRemoveEmployeeFromRole}
      />
      <TableDS.Pagination
        count={selectedRole?.membersCount || 0}
        pagination={pagination}
        onPaginationChange={({ pageSize, pageNumber }: PaginationState) => {
          setPagination({ ...pagination, pageSize, pageNumber });
          onPaginationChanged({ pageSize, pageIndex: pageNumber });
        }}
        pageSizeOptions={[
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
          {
            label: "50 itens",
            value: 50,
          },
          {
            label: "100 itens",
            value: 100,
          },
          {
            label: "250 itens",
            value: 250,
          },
        ]}
      />
    </TableDS.Root>
  );
};
