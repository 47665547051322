import { useNavigate } from "react-router-dom";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, Wrapper } from "./styled";

import { routes } from "src/routes/index";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <Typography variant="headline6">Acessos e permissões</Typography>
        <Typography variant="body4">
          Defina diferentes níveis de permissão para as pessoas da empresa.
        </Typography>
      </Wrapper>
      <span>
        <Button
          size="large"
          variant="primary"
          onClick={() => navigate(routes.pageRolesCreate)}
        >
          Criar novo perfil de permissão <Icons name="IconPlus" size={24} />
        </Button>
      </span>
    </Container>
  );
};
