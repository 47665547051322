import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Icons, Skeleton, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { PageTemplate, Banner } from "@components/index";
import { PermissionTabs } from "./components/PermissionsTabs";
import {
  Container,
  ContentContainer,
  TextWrapper,
  FormWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledDescription,
} from "./styled";
import { routes } from "src/routes/index";
import {
  useEditRolePermissionsMutation,
  useRolesAndPermissionsQuery,
} from "./queries";

export const PageRolePermissions = () => {
  const navigate = useNavigate();
  const { roleId } = useParams() as { roleId: string };

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const { data: roleData, isLoading: isLoadingPermissionsData } =
    useRolesAndPermissionsQuery(roleId);

  const {
    mutate: editPermissionsMutation,
    isLoading: isLoadingEditRolePermissions,
    error,
  } = useEditRolePermissionsMutation();

  useEffect(() => {
    if (roleData?.role) setSelectedPermissions(roleData?.role.permissions);
  }, [roleData]);

  const handlePermissionChange = (props: {
    ids: string[];
    checked: boolean;
  }) => {
    const permissionsCopy = [...selectedPermissions];
    props.ids.forEach((id) => {
      if (props.checked && !permissionsCopy.includes(id)) {
        permissionsCopy.push(id);
      }
      if (!props.checked) {
        const index = permissionsCopy.indexOf(id);
        if (index > -1) {
          permissionsCopy.splice(index, 1);
        }
      }
    });
    setSelectedPermissions(permissionsCopy);
  };

  const isReadOnly = !!roleData?.role.builtIn;

  const routesBread = [
    { label: "Gerenciar empresa", route: routes.pageInitial },
    {
      label: "Acessos",
      route: routes.pageRoles + `/${roleId}`,
    },
    { label: `${isReadOnly ? "Visualizar" : "Editar"} Perfil` },
  ];

  return (
    <PageTemplate
      routes={routesBread}
      footer={{
        goBackProps: {
          callback: () => navigate(routes.pageRoles + `/${roleId}`),
        },
        cancelProps: {
          callback: () => navigate(routes.pageRoles + `/${roleId}`),
        },
        confirmProps: {
          loading: isLoadingEditRolePermissions,
          disabled: isLoadingPermissionsData || isLoadingEditRolePermissions,
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" />
            </>
          ),
          callback: () => {
            if (isReadOnly) {
              navigate(routes.pageRolesEmployees.replace(":roleId", roleId));

              return;
            }

            editPermissionsMutation({
              roleId: roleId,
              permissions: selectedPermissions,
            });
          },
        },
      }}
    >
      <Container>
        <StyledTitle variant="headline6">
          {isLoadingPermissionsData ? (
            <Skeleton variant="text" width="361px" />
          ) : (
            `${isReadOnly ? "Visualizar" : "Editar"} perfil de permissão`
          )}
        </StyledTitle>
        <ContentContainer>
          <TextWrapper>
            <StyledSubtitle variant="headline8">
              {isLoadingPermissionsData ? (
                <Skeleton variant="text" width="201px" />
              ) : (
                `${isReadOnly ? "Visualizar" : "Configurar"} permissões`
              )}
            </StyledSubtitle>
            <StyledDescription variant="body3">
              {isLoadingPermissionsData ? (
                <Skeleton variant="text" width="312px" />
              ) : (
                `${
                  isReadOnly
                    ? "Visualize as ações que estão atreladas ao perfil de permissão."
                    : "Defina as ações que estarão atreladas ao perfil de permissão."
                }`
              )}
            </StyledDescription>
          </TextWrapper>
          <FormWrapper>
            {isReadOnly && (
              <Banner
                type="info"
                icon="IconAlertTriangle"
                title="Não é possível editar as permissões deste perfil pois ele é padrão do sistema."
                subTitle="Para ter flexibilidade de edição, crie um perfil de permissão personalizado."
                hasHideBanner={true}
                children={
                  <LinkButton
                    variant="secondary"
                    onClick={() => navigate(routes.pageRolesCreate)}
                  >
                    Criar perfil de permissão
                  </LinkButton>
                }
                style={{ marginBottom: "32px" }}
              />
            )}
            {roleData && (
              <PermissionTabs
                modules={roleData?.modules}
                role={roleData.role}
                selectedPermissions={selectedPermissions}
                loading={isLoadingPermissionsData}
                error={Boolean(error)}
                handleChange={handlePermissionChange}
              />
            )}
          </FormWrapper>
        </ContentContainer>
      </Container>
    </PageTemplate>
  );
};
