import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  EmptyState,
  GenericProfilePicture,
  Skeleton,
  Typography,
  Menu,
  Icons,
  IconButton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
  MenuOption,
} from "./styled";

import { ITableGrid } from "./types";

import { StatusTag } from "@components/StatusTag";
import { routes } from "src/routes/index";

export const TableGrid = ({
  employees,
  loading,
  hasRoles,
  paginationInfo,
  fetchData,
  onRemoveAdmin,
}: ITableGrid) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={data?.cell?.row?.original?.name}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{data.value}</Typography>
                {!!email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: string }) => <StatusTag status={value} />,
      },
      {
        Header: "CPF",
        accessor: "cpf",
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <p>{value}</p>,
      },
      {
        Header: "Ações",
        accessor: "actions",
        sticky: "right",
        disableSortBy: true,
        Cell: (data: any) => {
          const employeeMetadata = data?.cell?.row?.original.metadata;
          const disabled = employeeMetadata?.isLoggedAdmin;
          const menuOption = (
            <>
              <MenuOption disabled={disabled}>
                <Icons
                  name="IconUserOff"
                  fill="transparent"
                  color={disabled ? "gray" : undefined}
                />
                <Typography
                  variant="body3"
                  variantColor={disabled ? "gray" : undefined}
                >
                  Desvincular do perfil de permissão
                </Typography>
              </MenuOption>
            </>
          );
          return (
            <Menu
              type={"select"}
              options={[
                {
                  onClick: () => onRemoveAdmin(data?.cell?.row?.original),
                  children: disabled ? (
                    <Tooltip
                      title="Você não pode desvincular seu próprio perfil de administrador."
                      placement="left"
                    >
                      <div>{menuOption}</div>
                    </Tooltip>
                  ) : (
                    menuOption
                  ),
                },
              ]}
              disableAutoFocusItem={true}
              anchorOrigin={{ vertical: 50, horizontal: -150 }}
            >
              <IconButton variant="line" size="small" icon="IconDotsVertical" />
            </Menu>
          );
        },
      },
    ],
    [onRemoveAdmin]
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!hasRoles)
    return (
      <EmptyState
        buttonText={"Criar novo perfil de permissão"}
        description={"Você ainda não criou nenhum perfil de permissão."}
        buttonProps={{
          size: "medium",
          onClick: () => navigate(routes.pageRolesCreate),
        }}
      />
    );

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={employees || []}
      fetchData={fetchData}
      initialState={{ pageSize: 10 }}
      pageCount={paginationInfo.totalPages}
      pageSizeOptions={[
        {
          label: "10 itens",
          value: 10,
        },
        {
          label: "25 itens",
          value: 25,
        },
        {
          label: "50 itens",
          value: 50,
        },
        {
          label: "100 itens",
          value: 100,
        },
        {
          label: "250 itens",
          value: 250,
        },
      ]}
    />
  );
};
