import { Table, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { useTable } from "./table";

export default function Grid() {
  const { table, loading } = useTable();

  if (loading)
    return <Skeleton variant="rounded" height={"400px"} width={"100%"} />;

  return (
    <Table.Grid.Root>
      <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
      {table.rows.map((row, index) => (
        <Table.Grid.Row key={index + row.id} row={row} />
      ))}
    </Table.Grid.Root>
  );
}
