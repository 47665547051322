import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { FooterContainer } from "./styled";

type FooterProps = {
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  disabled: boolean;
};

export const Footer = ({
  onClose,
  onSubmit,
  loading,
  disabled,
}: FooterProps) => {
  return (
    <FooterContainer>
      <LinkButton
        variant="primary"
        onClick={onClose}
        style={{ alignSelf: "center" }}
      >
        Cancelar
      </LinkButton>
      <Button
        variant={"primary"}
        variantType={"default"}
        buttonType={"primary"}
        size={"large"}
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
      >
        Confirmar
      </Button>
    </FooterContainer>
  );
};
