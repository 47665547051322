import { Outlet, Navigate } from "react-router-dom";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

export const RoutesGuard = ({
  children,
  permission,
}: {
  children?: JSX.Element;
  permission: string;
}) => {
  const { selectedCompany } = useSelectedCompany();
  const permissions = usePermissions();

  const isRouteAllowed = permissions.companies
    .find((c) => c.id === selectedCompany.id)
    ?.permissions.includes(permission);

  if (!isRouteAllowed && !permissions.isAdmin) {
    return <Navigate to={"/home"} />;
  }
  return children ? children : <Outlet />;
};
