import { trpc } from "@api/client";
import { dispatchToast } from "@utils/dispatchToast";

type useRoleEmployeesQueryInput = {
  roleId: string;
  pageSize: number;
  page: number;
  searchQuery?: string;
  companyId?: string;
  sortBy?: "name" | "status" | "documentNumber";
  order?: "asc" | "desc";
};

export const useRoleEmployeesQuery = (input: useRoleEmployeesQueryInput) => {
  const { data, isInitialLoading, refetch } =
    trpc.getRoleEmployeesPaginated.useQuery(input, {
      enabled: !!input.roleId,
      onError: () => {
        dispatchToast({
          content: "Não foi possível obter os perfis",
          type: "error",
        });
      },
    });

  return { data, isLoading: isInitialLoading, fetch: refetch };
};

export const useRolesQuery = (input: { companyId: string }) => {
  const { data, isLoading } = trpc.listRoles.useQuery(
    { companyId: input.companyId },
    {
      onError: (error) => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar as permissões",
          description: error.message,
        });
      },
    }
  );

  return { data, isLoading };
};

export const useDeleteRoleMutation = (input: { companyId: string }) => {
  const utils = trpc.useContext();
  const { mutate } = trpc.deleteRole.useMutation({
    onSuccess: async (_, { roleId }) => {
      await utils.listRoles.cancel();
      utils.listRoles.setData({ companyId: input.companyId }, (prev) => {
        if (prev) {
          const index = prev.findIndex((a) => a.id === roleId);
          prev?.splice(index, 1);
        }
        return prev;
      });
      dispatchToast({
        content: "Perfil excluído com sucesso!",
        type: "success",
      });
    },
    onError: (error) => {
      dispatchToast({
        content: "Erro ao excluir perfil de permissão",
        type: "error",
        description: error.message,
      });
    },
  });

  return { mutate };
};

export const useDeleteEmployeeFromRoleMutation = (input: {
  employeeName: string;
  companyId: string;
}) => {
  const utils = trpc.useContext();
  const { mutate } = trpc.deleteEmployeeFromRole.useMutation({
    onSuccess: async (_, { roleId }) => {
      await utils.getRoleEmployeesPaginated.invalidate();

      await utils.listRoles.cancel();
      utils.listRoles.setData({ companyId: input.companyId }, (prev) => {
        const role = prev?.find((a) => a.id === roleId);
        if (role) role.membersCount -= 1;
        return prev;
      });

      dispatchToast({
        content: `A pessoa ${input.employeeName} foi desvinculada do perfil de permissão com sucesso!`,
        type: "success",
      });
    },
    onError: (error) => {
      dispatchToast({
        content: "Erro ao remover esta pessoa deste perfil de permissionamento",
        type: "error",
        description: error.message,
      });
    },
  });

  return { mutate };
};
