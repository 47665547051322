import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const TransferContainer = styled.div`
  padding: 40px;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.hr`
  color: #ebe6e9;
  margin: 40px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 312px;
`;

export const PrimaryTypography = styled(Typography)`
  color: #f20d7a;
`;

export const Spacer = styled.div<{ width?: number; height?: number }>`
  width: ${(props) => props.width || 1}px;
  height: ${(props) => props.height || 1}px;
`;

export const TransferListArea = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  height: 800px;

  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;

  margin-bottom: 15px;
`;
