import { createContext, useContext, Dispatch, useMemo, useEffect } from "react";
import {
  Checkbox,
  Table as TableDS,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Table as TableType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination/index";
import Grid from "./grid";
import { ColumnDef } from "@tanstack/react-table";

import { Container } from "./styled";
import { DataType, useTransferList } from "../Root";

type TableContextProps = {
  loading: boolean;
  table: TableType<any>;
};

const TableContext = createContext({} as TableContextProps);

export type tableProps<T> = {
  loading: boolean;
  tableTitle: string;
  tableTagLabel: string;
  data: T[];
  pagination: PaginationState;
  onSearchChange: (value: string) => void;
  onPaginationChange: Dispatch<PaginationState>;
  columns: ColumnDef<T>[];
  dataSize: number;
};

export function Table<T extends DataType>({
  loading,
  tableTitle,
  tableTagLabel,
  data,
  columns,
  pagination,
  onSearchChange,
  onPaginationChange,
  dataSize,
}: tableProps<T>) {
  const { selected, addSelected, removeSelected } = useTransferList();

  const tableColumns = useMemo(() => {
    columns.unshift({
      id: "select",
      header: ({ table }) => {
        const tableRows = table.getRowModel().rows;
        const tableData = tableRows
          .map(({ original }) => original)
          .filter(({ metaData }) => !metaData?.isDisabled);

        const everyRowFromPageIsSelected =
          !!tableData.length &&
          tableData.every(({ id }) => selected.find((item) => item.id === id));

        const someRowFromPageIsSelected = tableData.some(({ id }) =>
          selected.find((item) => item.id === id)
        );

        return (
          <Checkbox
            checked={everyRowFromPageIsSelected}
            indeterminate={
              someRowFromPageIsSelected && !everyRowFromPageIsSelected
            }
            onChange={(event) => {
              if (event.target.checked) {
                addSelected(tableData);
              } else {
                removeSelected(tableData);
              }
            }}
          />
        );
      },
      cell: ({ row }) => {
        const rowData = row.original;
        const checked = selected.find(({ id }) => id === rowData.id);
        return (
          <Checkbox
            checked={checked}
            disabled={rowData.metaData?.isDisabled}
            onChange={(event) => {
              if (event.target.checked) {
                addSelected([rowData]);
              } else {
                removeSelected([rowData]);
              }
            }}
          />
        );
      },
    });
    return columns;
  }, [addSelected, columns, removeSelected, selected]);

  const table = tableControllers.useTableColumns<T>({
    columns: tableColumns as any,
    data: data.map((data) => ({
      ...data,
    })),
    pagination: pagination,
    onPaginationChange: onPaginationChange,
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [search, debounceSearch] = tableControllers.useSearch(() => {}, "", {
    wait: 200,
  }) as [string, (_: string) => void];

  useEffect(() => {
    onPaginationChange({
      pageSize: pagination.pageSize,
      pageNumber: 1,
    });
    onSearchChange(search);
  }, [search, onSearchChange, onPaginationChange, pagination.pageSize]);

  return (
    <TableContext.Provider
      value={{
        loading,
        table,
      }}
    >
      <Container>
        <TableDS.Header
          title={tableTitle}
          tagLabel={tableTagLabel}
          tag={"gray"}
          loading={loading}
        />

        <TableDS.Search
          label="Pesquisar..."
          onChange={(e) => {
            debounceSearch(e.target.value);
          }}
        />

        <Grid />
        <TableDS.Pagination
          count={dataSize}
          onPaginationChange={onPaginationChange}
          pagination={pagination}
        />
      </Container>
    </TableContext.Provider>
  );
}

export const useTable = () => {
  return useContext(TableContext);
};
