import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 70%;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.s};
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;
