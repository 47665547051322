import { Routes, Route } from "react-router-dom";

import { RoutesGuard } from "./routesGuard";
import { PagePermissionsCreate } from "src/pages/PagePermissionsCreate";
import { PageRolePermissions } from "src/pages/PageRolePermissions";
import { PageRoleEmployees } from "src/pages/PageRoleEmployees";
import { PagePermissions } from "src/pages";
import { PageGlobalAdmins } from "src/pages/PageGlobalAdmins";
import PageSetAdmins from "src/pages/PageSetAdmins";

export const routes = {
  pageInitial: "/settings",
  pageRoles: "/settings/permissions",
  pageRolesCreate: "/settings/permissions/create",
  pageRolesPermissions: "/settings/permissions/:roleId/permissions",
  pageRolesEmployees: "/settings/permissions/:roleId/employees",
  pageGlobalAdmins: "/settings/permissions/administrators",
  pageSetGlobalAdmins: "/settings/permissions/administrators/add",
} as const;

const AppRouter = () => {
  return (
    <Routes>
      <Route
        element={<RoutesGuard permission="core_manage_company_settings" />}
      >
        <Route path={routes.pageRoles}>
          <Route index element={<PagePermissions />} />
          <Route path={":roleId"} element={<PagePermissions />} />
          <Route
            path={":roleId/permissions"}
            element={<PageRolePermissions />}
          />
          <Route path={":roleId/employees"} element={<PageRoleEmployees />} />
          <Route path={"create"} element={<PagePermissionsCreate />} />
        </Route>
      </Route>
      <Route element={<RoutesGuard permission="admin" />}>
        <Route path={routes.pageGlobalAdmins} element={<PageGlobalAdmins />} />
        <Route path={routes.pageSetGlobalAdmins} element={<PageSetAdmins />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
