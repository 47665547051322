import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";
import { Container } from "./styled";

import { routes } from "src/routes/index";

interface TableActionsProps {
  disabled: boolean;
  roleId: string;
}

export const TableActions = ({ disabled, roleId }: TableActionsProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Button
        variant="secondary"
        size="small"
        disabled={disabled}
        onClick={() =>
          navigate(routes.pageRolesEmployees.replace(":roleId", roleId))
        }
      >
        Adicionar integrantes
      </Button>
    </Container>
  );
};
