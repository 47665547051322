import {
  ReactNode,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { uniqBy } from "lodash";
import { Container } from "./styled";

type RootContextProps<T extends object & { id: string }> = {
  addSelected: (itemsToAdd: Array<T>) => void;
  removeSelected: (itemsToRemove: Array<T>) => void;
  selected: Array<T>;
  selectedFiltered: Array<T>;
  setSelectedFiltered: Dispatch<SetStateAction<T>>;
};

export type RootProps<T> = {
  children: ReactNode;
  selected: Array<T>;
  setSelected: Dispatch<SetStateAction<Array<T>>>;
};

const RootContext = createContext<RootContextProps<any>>(
  {} as RootContextProps<any>
);

export type DataType = {
  id: string;
  metaData?: { isDisabled?: boolean; isChecked?: boolean };
};

export function Root<T extends DataType>({
  children,
  selected,
  setSelected,
}: RootProps<T>) {
  const [selectedFiltered, setSelectedFiltered] = useState<T[]>([]);

  const addSelected = (itemsToAdd: T[]) => {
    setSelected((oldValue) => {
      return uniqBy([...oldValue, ...itemsToAdd], "id");
    });
  };

  const removeSelected = (itemsToRemove: T[]) => {
    const itemsToRemoveIds = itemsToRemove.map(({ id }) => id);
    setSelected((oldValue) =>
      oldValue.filter(({ id }) => !itemsToRemoveIds.includes(id))
    );
  };

  return (
    <RootContext.Provider
      value={{
        addSelected,
        removeSelected,
        selected,
        selectedFiltered,
        setSelectedFiltered,
      }}
    >
      <Container gap={"s"}>{children}</Container>
    </RootContext.Provider>
  );
}

export const useTransferList = () => {
  return useContext(RootContext);
};
