import styled from "styled-components";
import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 40px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-bottom: 40px;

  @media screen and (min-width: 900px) {
    display: flex;
    flex-direction: row;
  }
`;
