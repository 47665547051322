import styled from "styled-components";
import { Icons, Modal, Typography } from "@flash-tecnologia/hros-web-ui-v2";

const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 24px;
    }
  }
`;

const AlertWrapper = styled.div`
  margin: 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
`;

const AlterIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 420px;
  text-align: center;
  margin: 20px 48px;

  @media screen and (max-width: 768px) {
    margin: 20px 30px;
  }
`;

const Warning = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

const Title = styled(Typography)`
  && {
    font-weight: 700;

    color: ${({ theme }) => theme.colors.neutral[10]};
  }

  margin-bottom: 16px;
`;

const Subtitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

export {
  StyledModal,
  AlertWrapper,
  AlterIcon,
  Container,
  TextWrapper,
  Warning,
  Title,
  Subtitle,
};
