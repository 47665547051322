import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 26px;
  padding: 24px 0px;
`;

export const FooterCancelButton = styled(LinkButton)`
  margin: 16px 0;

  @media screen and (max-width: 768px) {
    & {
      font-size: 14px !important;
    }
  }
`;

export const SubmitButton = styled(Button)``;

export const SubmitButtonText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
