import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;

  @media screen and (min-width: 900px) {
    align-items: flex-start;
    max-width: 270px;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 40px;
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const TransferListArea = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  height: 800px;

  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;

  margin-bottom: 15px;
`;
